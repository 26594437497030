<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1"></top>
      <div class="container-box copyrightRegistration bg-white flex-column justify-content-start align-items-center">
        <div class="width-100 border-b flex-row justify-content-spaceBetween align-items-center">
          <p class="fs-super-big black fw-mid">金融服务</p>
          <p class="darkgrey opacity-5">可以向银行提出版权质押申请</p>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="姓名" prop="applicantName">
              <el-input v-model="form.applicantName" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="企业名称" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="form.contactName" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contactMobile">
              <el-input v-model="form.contactMobile" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="版权作品" prop="copyrightRegistrationId">
              <el-select v-model="form.copyrightRegistrationId" placeholder="请选择版权作品" style="width: 100%">
                <el-option v-for="(item, index) in copyrightRegistrationDist" :key="index" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所需金额" prop="needMoney">
              <el-input v-model="form.needMoney" placeholder="请输入所需金额" @input="needMoneyInput"></el-input>
            </el-form-item>

            <el-form-item label="内容说明" prop="content">
              <el-input v-model="form.content" type="textarea" :autosize="{ minRows: 10, maxRows: 15 }" placeholder="请输入内容说明"></el-input>
            </el-form-item>

            <el-form-item label="备注信息" prop="remarks">
              <el-input type="textarea" v-model="form.remarks" :autosize="{ minRows: 10, maxRows: 15 }"></el-input>
            </el-form-item>
            <!-- <el-form-item label="附件">
              <div class="width-85 flex-row justify-content-start align-items-star">
                <el-upload
                  class="avatar-uploader"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="form.logo" :src="form.logo" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { financeBankInfo } from '@/api/TranFinanceBank';
import { financeServiceApplySave } from '@/api/TranFinanceServiceApply';
import { myRegistrationList } from '@/api/tranCopyrightRegistration';
import { dealMoney } from '@/util/helper';

export default {
  name: 'financialServiceApplication',
  components: {
    top,
    bottom
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_SERVE_PATH + '/common/upload', // 上传的图片服务器地址
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      uploadProfile: {
        fileSize: 2, // 文件最大尺寸
        fileType: ['jpg', 'png'] // 文件格式限制
      },
      headers: {
        Authorization: localStorage.getItem('bqsb_login')
      },
      financialServiceId: null,
      financialService: null,
      form: {
        applicantName: '', //姓名
        companyName: '', //企业名称
        contactName: '', //联系人
        contactMobile: '', //联系方式
        copyrightRegistrationId: '', //版权作品
        needMoney: '', //所需金额
        content: '', //内容说明
        // other: '', //附件
        remark: '' //备注信息
      },
      rules: {
        applicantName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactMobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        copyrightRegistrationId: [{ required: true, message: '请输入版权作品', trigger: 'change' }],
        needMoney: [{ required: true, message: '请输入所需金额', trigger: 'blur' }],
        content: [{ required: true, message: '请输入版权质押的用途等信息', trigger: 'blur' }]
      },
      copyrightRegistrationDist: [],
      queryParams: {
        title: null,
        audit: '1',
        copyrightTypeOn: null,
        authorizationType: null,
        copyrightUsage: null,
        pageNum: 1,
        pageSize: 999,
        params: {
          myself: 'true',
          searchText: null,
          sortRule: null
        }
      }
    };
  },
  created() {
    this.financialServiceId = this.$route.query.id;
    this.form.bankId = this.$route.query.id;
    financeBankInfo(this.financialServiceId).then((res) => {
      this.financialService = res.data;
    });
    this.getCopyrightData();
  },
  methods: {
    async onSubmit() {
      const dataForm = this.$refs.form;
      await dataForm.validate().then(
        async () => {
          financeServiceApplySave(this.form).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！');
              this.$router.go(-1);
            }
          });
        },
        () => {
          // TODO
        }
      );
    },
    handleImageSuccess(res) {
      this.loading.close();
      this.form.image = res.fileName;
    },
    beforeImageUpload(file) {
      return new Promise((resolve, reject) => {
        let isImg = false;
        console.log(file);
        if (this.uploadProfile.fileType.length) {
          let fileExtension = '';
          if (file.name.lastIndexOf('.') > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
          }
          isImg = this.uploadProfile.fileType.some((type) => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
        } else {
          isImg = file.type.indexOf('image') > -1;
        }

        if (!isImg) {
          this.$message.error(`文件格式不正确, 请上传${this.uploadProfile.fileType.join('/')}图片格式文件!`);
          return reject(false);
        }
        if (file.name.lastIndexOf(',') > -1) {
          this.$message.error(`文件名格式不正确, 请确保文件名内不出现’,‘符号!`);
          return reject(false);
        }
        if (this.uploadProfile.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.uploadProfile.fileSize;
          if (!isLt) {
            this.$message.error(`上传图片大小不能超过 ${this.uploadProfile.fileSize} MB!`);
            return reject(false);
          }
        }
        this.loading = this.$loading({
          lock: true,
          text: '上传中',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        return resolve(file);
      });
    },
    async getCopyrightData() {
      const list = await myRegistrationList(this.queryParams);
      console.log('list:', list);
      this.copyrightRegistrationDist = list.rows.map((row) => {
        return {
          title: row.title,
          id: row.id
        };
      });
    },
    needMoneyInput(value) {
      if (value.lastIndexOf('.') !== value.length - 1) {
        this.form.needMoney = dealMoney(value);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .form-box {
      padding: 60px 0;
    }
  }
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 30px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}
.copyrightRegistration .el-select {
  width: 100%;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}

.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
</style>
